/*=====================================================
15. shop-home-2
======================================================*/

/* 15.1 wpo-category-section-s3 */

.wpo-category-section-s3 {
    .wpo-category-wrap {
        .wpo-category-item {
            margin-bottom: 30px;

            .wpo-category-img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                background: #FFF;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
                padding: 15px;
                max-width: 375px;
                margin: 0 auto;

                .wpo-category-img-inner {
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        -webkit-transition: all .3s;
                        transition: all .3s;
                        -webkit-transform: scale(1);
                        transform: scale(1);

                    }
                }

                img {
                    border-radius: 50%;
                    width: 100%;
                }
            }

            &:hover {
                .wpo-category-img {
                    .wpo-category-img-inner {
                        img {
                            -webkit-transform: scale(1.2) rotate(5deg);
                            transform: scale(1.2) rotate(5deg);

                        }
                    }
                }
            }


            .wpo-category-text {
                text-align: center;
                margin-top: 30px;

                a {
                    text-align: center;
                    font-family: $base-font;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $dark-gray;

                    @media(max-width:1399px) {
                        font-size: 25px;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }
}

/*15.2 wpo-offer-banner-section*/

.wpo-offer-banner-section {
	background: url(../../images/offer.jpg) no-repeat center center;
	height: 600px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding-left: 150px;

	@media(max-width:1399px) {
		padding-left: 50px;
	}

	@media(max-width:1199px) {
		padding-left: 0px;
	}

	@media(max-width:991px) {
		height: 500px;
	}

	@media(max-width:575px) {
		height: 400px;
	}


	.offer-banner-text {
		padding: 20px;
		background: $white;
		max-width: 820px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-align: center;
		border: 1px solid $theme-primary-color-s7;

        .frame-shape-1 {
			position: absolute;
			left: -70px;
			bottom: -85px;
			z-index: -1;

			@include media-query(991px) {
				left: -50px;
				bottom: -100px;
			}

			@media(max-width:575px) {
				max-width: 240px;
				bottom: -30px;
				left: -30px;
			}
		}

		.frame-shape-2 {
			position: absolute;
			right: -70px;
			top: -85px;
			z-index: -1;

			@include media-query(991px) {
				right: -50px;
				top: -100px;
			}

			@media(max-width:575px) {
				max-width: 240px;
				top: -30px;
				right: -30px;
			}
		}

		@media(max-width:575px) {
			padding: 10px;
			text-align: center;
		}

		.offer-banner-text-inner {
			padding: 80px;
			border: 1px solid $theme-primary-color-s7;

			@media(max-width:991px) {
				padding: 40px;
				text-align: center;
			}

			@media(max-width:575px) {
				padding:50px 20px;
				text-align: center;
			}

		}

		span {
			font-weight: 400;
			font-size: 25px;
			line-height: 51px;
			color: $theme-primary-color-s7;

			@media(max-width:991px) {
				font-size: 25px;
				line-height: 40px;
			}

			@media(max-width:575px) {
				font-size: 18px;
				line-height: 25px;
			}
		}

		h2 {
			font-weight: 400;
			font-size: 50px;
			line-height: 116px;
			margin-bottom: 20px;

			@media(max-width:1200px) {
				font-size: 40px;
			}


			@media(max-width:991px) {
				font-size: 50px;
				line-height:80px;
			}

			@media(max-width:767px) {
				font-size: 40px;
			}
			@media(max-width:575px) {
				font-size: 30px;
				line-height: 42px;
			}
		}

        .theme-btn {
            background-color: $theme-primary-color-s7;

            &:hover {
                background-color: darken($theme-primary-color-s7, 8);
            }
        }
	}
}