/*=====================================================
17. wpo-service-single
======================================================*/

.wpo-service-details-area{
    .container{
        @media(min-width:1440px){
          max-width: 1320px;
        }
    }
}
.theme-accordion-s1 {
    margin-bottom: 0;
}

.accordion-button{
    font-size: 18px;
}

.accordion-button:not(.collapsed){
    background-color: $white;
    color: $dark-gray;
    box-shadow: none;
}
.accordion-button:focus{
    box-shadow: none;
}

.accordion-item{
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    margin-bottom: 30px;
    border: 0;

    h3{
        font-family: $base-font;
        font-weight: 600;
    }

}
.accordion-collapse{
    border-top: 1px solid #ebebeb;

    p{
        font-size: 15px;
    }
}

.theme-accordion-s1 .panel-default {
    background: transparent;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.theme-accordion-s1 .panel-heading {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
}

.theme-accordion-s1 .panel+.panel {
    margin-top: 15px;
}

.theme-accordion-s1 .panel-heading a {
    background: #999;
    font-size: 18px;
    font-size: 1.125rem;
    color: #fff;
    display: block;
    padding: 18px 25px;
    position: relative;
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading a {
        font-size: 16px;
        font-size: 1rem;
        padding: 15px 25px;
    }
}

@media (max-width: 767px) {
    .theme-accordion-s1 .panel-heading a {
        padding: 12px 15px;
    }
}

.theme-accordion-s1 .panel-heading a:before {
    font-family: "themify";
    content: "\e64b";
    font-size: 15px;
    font-size: 0.9375rem;
    position: absolute;
    right: 25px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading a:before {
        font-size: 18px;
        font-size: 1.125rem;
        right: 20px;
    }
}

.theme-accordion-s1 .panel-heading a:focus {
    text-decoration: none;
}

.theme-accordion-s1 .panel-heading .collapsed {
    background-color: #fff;
    color: #242f6c;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

.theme-accordion-s1 .panel-heading .collapsed:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
    background-color: #fff;
    border: 0;
    padding: 40px 25px 15px;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
        padding: 20px 25px 10px;
        font-size: 15px;
        font-size: 0.9375rem;
    }

    .all-wpo-services{
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
        padding: 15px 15px 8px;
    }
}

.theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body p {
    margin-bottom: 1.3em;
}

.wpo-minimal-wrap {
    position: relative;
    margin-bottom: 50px;

    .minimals-img{
        img{
            width: 100%;
        }
    }
}

.minimals-img{
    img{
        width: 100%;
    }
}
.wpo-minimal-wrap .video-holder {
    position: relative;
    text-align: center;
}

.wpo-minimal-wrap .video-holder:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(10, 67, 149, .51);
}

.wpo-minimal-wrap .video-holder a {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 83px;
    height: 83px;
    border-radius: 83px;
    display: inline-block;
    -webkit-animation: spineer 2s infinite;
    animation: spineer 2s infinite;
    z-index: 9;
}

.wpo-minimal-wrap .video-holder a:before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid $dark-gray;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.wpo-minimal-wrap .video-holder {
    position: absolute;
    left: 50%;
    top: 50%;
}

.wpo-minimal-wrap .video-holder a,
.video-holder a {
    background-color: #fff;
    -webkit-animation: spineerb 2s infinite;
    animation: spineerb 2s infinite;
}
@-webkit-keyframes spineerb {
    0% {
        -webkit-box-shadow: 0 0 0 0 #a9a5f8;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes spineerb {
    0% {
        -webkit-box-shadow: 0 0 0 0 #a9a5f8;
        box-shadow: 0 0 0 0 #a9a5f8;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

.wpo-service-details-text {
    text-align: center;
    padding: 40px;
    background: $theme-primary-color-s2;

}

.wpo-service-details-text-3 {
    text-align: center;
    padding: 40px;
    background: darken($theme-primary-color-s2, 5%);
}

.wpo-service-details-list{
    .col{
        padding: 0;
    }

    .row{
        margin: 0 0px;
    }
}


.wpo-service-details-text h2,
.wpo-service-details-text-3 h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0;
}

.wpo-service-details-text span,
.wpo-service-details-text-3 span {
    font-size: 16px;
    color: #fafafa;
    display: block;
    margin-bottom: 10px;
}

.wpo-p-details-section {
    margin-top: 50px;

    ul{
        list-style: none;
    }

    h5{
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 500;
        font-family: $base-font;
    }
    .process-wrap{
        margin-top: 50px;
        .process-item{
            margin-bottom: 30px;
            padding: 26px;
            border: 1px solid #ebebeb;
            h3{
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 10px;
                font-family: $base-font;
            }
            p{
                font-size: 15px;
            }

            .process-icon{
                margin-bottom: 10px;
                .fi{
                    &:before{
                        font-size: 45px;
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }

}

.wpo-p-details-section p {
    margin-bottom: 30px;
    font-size: 16px;

    &:nth-child(2){
        margin-bottom: 20px;
    }
}

.wpo-p-details-quote {
    padding: 40px;
    background: $theme-primary-color;
    margin-top: 30px;
    padding-left: 80px;
    position: relative;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.wpo-p-details-quote:before {
    font-family: "Flaticon";
    content: "\f109";
    font-size: 40px;
    color: #fafafa;
    margin-left: 0;
    position: absolute;
    left: 5%;
    top: 22%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.wpo-p-details-img {
    margin-bottom: 30px;

    img{
        width: 100%;
    }
}

.wpo-p-details-quote p {
    color: #fff;
    margin-bottom: 20px;
}

.wpo-p-details-quote span {
    color: #fddada;
    text-decoration: underline;
}

.wpo-faq-section {
    padding-top: 60px;

    h4{
        font-size: 30px;
        margin-bottom: 30px;
        font-family: $base-font;
        font-weight: 500;
    }
    p{
        margin-bottom: 0;
    }
}


@media(max-width:1200px) {

    .wpo-service-details-text h2,
    .wpo-service-details-text-3 h2 {
        font-size: 18px;
    }
}

@media(max-width:767px) {

    .wpo-service-details-text h2,
    .wpo-service-details-text-3 h2 {
        margin-bottom: 30px;
    }

    .wpo-service-details-text-3 {
        border: none;
    }

    .wpo-service-details-list{
        padding-bottom: 10px;
        background: $dark-gray;
    }
}

@media(max-width:530px) {
    .wpo-p-details-quote:before {
        left: 12%;
        top: 17%;
    }
}

/*=======================================================
8. wpo-service-single-section
========================================================*/

.wpo-service-sidebar .all-wpo-services {
    background-color: #f8f8f8;
    padding: 60px 30px;
}
.wpo-service-sidebar .all-wpo-services ul{
    list-style: none;
}

.wpo-service-sidebar .widget h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 1em;
    padding-bottom: 17px;
    text-transform: capitalize;
    position: relative;
}

.wpo-service-sidebar .widget h3:before {
    content: "";
    background-color: $theme-primary-color;
    width: 55px;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
}

.wpo-service-sidebar .widget h3:after {
    content: "";
    background-color: #e4e1e1;
    width: 80%;
    height: 4px;
    position: absolute;
    left: 65px;
    bottom: 0;
    border-radius: 10px;
}

.wpo-service-sidebar .all-wpo-services ul a {
    margin-top: 15px;
    padding-bottom: 15px;
    display: block;
    border-bottom: 1px solid #e1e1e1;
    color: #666;
}

.wpo-service-sidebar .all-wpo-services ul a:hover,
.wpo-service-sidebar .all-wpo-services ul li.current a {
    color: $theme-primary-color;
}

.wpo-service-sidebar .all-wpo-services ul>li:last-child a {
    border: 0;
    padding: 0;
}

.wpo-service-sidebar>.widget+.widget {
    margin-top: 55px;
}

.wpo-service-sidebar .contact-widget {
    background: $dark-gray;
    padding: 60px 15px;
    text-align: center;
    position: relative;
}

.wpo-service-sidebar .contact-widget h5 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #dad9d9;
    margin: 0;
    margin-bottom: 30px;
}

.wpo-service-sidebar .contact-widget h4 {
    font-size: 35px;
    font-size: 2.1875rem;
    margin: 0.5em 0 0;
    color: #fff;
}

.wpo-p-details-section h4 {
    font-size: 40px;
    color: #121127;
    margin-bottom: 30px;
}

.wpo-p-details-section p:last-child {
    margin-bottom: 0;
}

@media(max-width: 991px) {
    .wpo-service-sidebar {
        margin-bottom: 40px;
    }
}

@media(max-width: 530px) {
    .wpo-service-sidebar .contact-widget h4 {
        font-size: 25px;
    }

    .wpo-service-sidebar .contact-widget h5 {
        font-size: 16px;
    }

    .wpo-p-details-section h4 {
        font-size: 35px;
    }
}

.wpo-minimal-img{
    img{
        width: 100%;
    }
}


.wpo-portfolio-section-s3.tb-padding{
    padding: 120px 0 110px;

    @media(max-width:991px){
        padding: 90px 0 80px;
    }
    @media(max-width:767px){
        padding: 70px 0 60px;
    }
}
