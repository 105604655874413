/*======================================
6. Home-style-3
 =======================================*/

/*6.1 wpo-wedding-date-s2 */

.wpo-wedding-date-s2 {
    .wedding-date-wrap {
        .react-countdown {
            .time-section {
                width: 230px;
                height: 280px;
                background: #C7D9F8;
                border-radius: 115px;

                @include media-query(1199px) {
                    width: 180px;
                    height: 230px;
                    margin: 0 20px;
                }

                @include media-query(991px) {
                    margin-bottom: 40px;
                }

                &:after {
                    left: 50%;
                    top: 50%;
                    width: 190px;
                    height: 240px;
                    border-radius: 95px;
                    border: 1px solid $white;
                    transform: translate(-50%, -50%);

                    @include media-query(1199px) {
                        width: 140px;
                        height: 190px;
                    }
                }

                &:before {
                    left: -50px;
                    bottom: -50px;
                    width: 190px;
                    height: 240px;
                    background: url(../../images/wedding-date/3.svg) no-repeat center center;
                }


                &:nth-child(even) {
                    &:before {
                        width: 170px;
                        left: -50px;
                        top: -50px;
                        background: url(../../images/wedding-date/4.svg) no-repeat center center;
                    }
                }

                .time {
                    color: #657150;
                    margin-top: -20px;

                    @include media-query(1400px) {
                        font-size: 50px;
                        line-height: 65px;
                    }
                }
            }
        }

    }
}

/* 6.2 wpo-story-section-s3 */

.wpo-story-section-s3,
.wpo-story-section-s6 {
    overflow: hidden;

    .wpo-story-item {
        display: flex;
        align-items: center;
        padding-top: 50px;


        &:first-child {
            padding-top: 0;
        }

        @media(max-width:991px) {
            flex-wrap: wrap;
            padding-top: 50px;
        }

        @media(max-width:575px) {
            padding-top: 50px;
        }


        .wpo-story-img-wrap {
            position: relative;
            z-index: 1;
            flex-basis: 60%;
            text-align: center;

            @media(max-width:1399px) {
                flex-basis: 50%;
            }

            @media(max-width:1199px) {
                flex-basis: 45%;
            }

            @media(max-width:991px) {
                flex-basis: 100%;
                max-width: 600px;
                margin: 0 auto;
            }

            @media(max-width:575px) {
                max-width: 400px;
            }

            .wpo-story-img {
                width: 500px;
                height: 500px;
                padding: 20px;
                background: $white;
                border-radius: 50%;
                margin: 0 auto;
                box-shadow: 0px 2px 12px 2px rgba(4, 0, 52, 0.10);

                @media(max-width:1399px) {
                    width: 360px;
                    height: 360px;
                }

                @media(max-width:575px) {
                    width: 260px;
                    height: 260px;
                }

                img {
                    border-radius: 50%;
                }
            }

            .wpo-img-shape {
                position: absolute;
                width: 100%;
                left: -50px;
                bottom: -75px;
                z-index: 1;

                @media(max-width:1399px) {
                    bottom: -45px;
                }

                @media(max-width:575px) {
                    left: -30px;
                    bottom: -40px;
                }

                img {
                    @media(max-width:1399px) {
                        max-width: 400px;
                    }

                    @media(max-width:575px) {
                        max-width: 285px;
                    }
                }
            }
        }

        .wpo-story-content {
            flex-basis: 50%;
            border: 1px solid #C7D9F8;
            padding: 20px;
            padding-left: 130px;
            margin-left: -450px;
            position: relative;
            background: #F8FAFF;
            border-top-right-radius: 350px;
            border-bottom-right-radius: 350px;

            @media(max-width:1800px) {
                margin-left: -350px;
                flex-basis: 55%;
            }

            @media(max-width:1600px) {
                margin-left: -300px;
                flex-basis: 60%;
            }

            @media(max-width:1399px) {
                margin-left: -280px;
                flex-basis: 70%;
            }

            @media(max-width:1199px) {
                flex-basis: 75%;
                margin-left: -150px;
            }

            @media(max-width:991px) {
                flex-basis: 100%;
                border-radius: 0;
            }

            @media(max-width:991px) {
                padding-left: 20px;
                margin-left: 0;
                text-align: center;
                margin-top: 100px;
            }

            @media(max-width:575px) {
                margin-top: 30px;
            }

            @media(max-width:575px) {
                padding: 10px;
            }


            .wpo-story-content-inner {
                position: relative;
                padding: 60px;
                padding-right: 200px;

                @media(max-width:1800px) {
                    padding: 40px;
                    padding-right: 100px;
                }

                @media(max-width:1399px) {
                    padding: 30px;
                    padding-right: 100px;
                }

                @media(max-width:991px) {
                    padding: 30px 15px;
                }

                @media(max-width:575px) {
                    padding: 30px 15px;
                }

                .inner-shape {
                    position: absolute;
                    right: -35px;
                    top: 50%;
                    transform: translateY(-50%);

                    @media(max-width:991px) {
                        display: none;
                    }
                }

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 150%;
                    height: 100%;
                    left: -50%;
                    content: "";
                    border: 1px solid #C7D9F8;
                    border-top-right-radius: 350px;
                    border-bottom-right-radius: 350px;

                    @media(max-width:1399px) {
                        width: 115%;
                        left: -15%;
                    }

                    @media(max-width:991px) {
                        width: 100%;
                        left: 0;
                        border-radius: 0;
                    }
                }

                h2 {
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 46px;
                    margin-bottom: 30px;

                    @media(max-width:1199px) {
                        margin-bottom: 20px;
                        font-size: 25px;
                        line-height: 36px;
                    }

                    @media(max-width:575px) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 29px;
                    color: $theme-primary-color-s2;
                    margin-bottom: 20px;
                    display: block;

                    @media(max-width:1199px) {
                        margin-bottom: 10px;
                    }

                    @media(max-width:575px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                    color: $text-color;
                    margin-bottom: 0;

                    @media(max-width:1399px) {
                        font-size: 18px;
                    }

                    @media(max-width:575px) {
                        font-size: 17px;
                    }
                }
            }


        }

        &:nth-child(even) {
            .wpo-story-img-wrap {
                order: 2;
                -webkit-order: 2;
                -moz-order: 2;


                @media(max-width:991px) {
                    order: unset;
                    -webkit-order: unset;
                    -moz-order: unset;
                }

                .wpo-img-shape {
                    left: auto;
                    right: -50px;

                    img {
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
                }
            }

            .wpo-story-content {
                order: 1;
                -webkit-order: 1;
                -moz-order: 1;
                flex: 1;
                padding-right: 165px;
                margin-left: 0;
                position: relative;
                padding-left: 0;
                flex-basis: 50%;
                max-width: 900px;
                margin: auto;
                margin-right: -430px;
                border-radius: 0;
                border-top-left-radius: 350px;
                border-bottom-left-radius: 350px;

                @media(max-width:1800px) {
                    margin-right: -350px;
                }

                @media(max-width:1399px) {
                    margin-right: -280px;
                    max-width: 850px;
                    padding-right: 135px;
                }

                @media(max-width:1199px) {
                    margin-right: -230px;
                }

                @media(max-width:991px) {
                    max-width: 100%;
                    border-radius: 0;
                    padding: 15px;
                }

                @media(max-width:991px) {
                    padding: 20px;
                    order: unset;
                    -webkit-order: unset;
                    -moz-order: unset;
                    flex-basis: 100%;
                    margin-right: 0;
                    text-align: center;
                    margin-top: 100px;
                }

                @media(max-width:575px) {
                    padding: 10px;
                    margin-top: 30px;
                }

                .wpo-story-content-inner {
                    position: relative;
                    padding: 60px;
                    text-align: right;
                    padding-right: 0;
                    padding-left: 200px;

                    @media(max-width:1800px) {
                        padding: 40px;
                        padding-left: 100px;
                    }

                    @media(max-width:1399px) {
                        padding: 30px;
                        padding-left: 80px;
                    }
                    @media(max-width:1199px) {
                        padding-right: 80px;
                    }

                    @media(max-width:991px) {
                        text-align: center;
                        padding: 30px 15px;
                    }

                    @media(max-width:575px) {
                        padding: 30px 15px;
                    }

                    &:before {
                        left: auto;
                        right: -53%;
                        border-radius: 0;
                        border-top-left-radius: 350px;
                        border-bottom-left-radius: 350px;

                        @media(max-width:1399px) {
                            right: -18%;
                        }

                        @media(max-width:991px) {
                            width: 100%;
                            left: 0;
                            border-radius: 0;
                        }

                    }

                    .inner-shape {
                        position: absolute;
                        right: auto;
                        left: -35px;
                        top: 50%;
                        transform: translateY(-50%);

                        img {
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                        }
                    }

                }


            }
        }
    }
}

/* 6.3 wpo-contact-section-s3 */

.wpo-contact-section-s3,
.wpo-contact-section-s6 {
    .contact-wrap {
        padding-top: 160px;
        padding-bottom: 200px;

        @media(max-width:767px) {
            padding: 0;
            padding-bottom: 30px;
        }

        .wpo-contact-section-wrapper {
            padding: 20px;
            background: $white;
            border-radius: 350px;

            @media(max-width:575px) {
                padding: 10px;
            }


            .wpo-contact-form-area {
                background: #96A4BC;
                border-radius: 350px;
                padding: 80px 40px 50px;
    
    
                @media(max-width:575px) {
                    padding: 30px;
                    padding-top: 60px;
                }
            }
        }

        .shape-1 {
            z-index: -1;
            left: -130px;
            display: block;

            @media(max-width:767px) {
                display: none;
            }

            img {
                max-width: 100%;
            }
        }

        .shape-2 {
            z-index: -1;
            right: -130px;
            display: block;

            @media(max-width:767px) {
                display: none;
            }

            img {
                max-width: 100%;
            }
        }
    }
}

/* 6.4 wpo-blog-section-s2 */

.wpo-blog-section-s2 {
    background-color: #F8FAFF;
}