.wpo-section-title,
.wpo-section-title-s2 {
	margin-bottom: 60px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	h4 {
		font-weight: 400;
		font-size: 35px;
		line-height: 70px;
		text-align: center;
		color: #939580;
		margin-bottom: 0;

		@include media-query(767px) {
			font-size: 25px;
			line-height: 42px;
			margin-bottom: 0;
		}

		@include media-query(575px) {
			font-size: 18px;
		}
	}

	h2 {
		font-weight: 400;
		font-size: 40px;
		line-height: 58px;
		margin: 0;
		position: relative;
		font-family: $base-font;
		color: $dark-gray;
		display: inline-block;
		padding-bottom: 20px;
		position: relative;
		margin-top: 10px;

		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 120%;
			height: 1px;
			content: "";
			background: #F3E1DB;
			left: -10%;
		}

		&::before {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -10px;
			width: 70%;
			height: 1px;
			content: "";
			background: #F3E1DB;
		}


		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 25px;
			margin-top: 0;
		}

	}

	p {
		font-size: 18px;
	}
}


.wpo-section-title-s2 {
	.section-title-img {
		.round-ball {
			position: absolute;
			left: 50%;
			width: 15px;
			height: 15px;
			border: 1px solid $theme-primary-color;
			border-radius: 50%;
			transform: translateX(-50%);
			top: -5px;
		}

		&:after,
		&:before {
			width: 144px;

			@include media-query(575px) {
				width: 80px;
			}
		}
	}
}