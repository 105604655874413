/*=====================================================
14. wpo-shop-home
======================================================*/

/* 14.1 wpo-category-section */

.wpo-category-section {
	.category-grids {
		max-width: 1520px;
		margin: 0 auto;

		.grid {
			text-align: center;
			height: 120px;
			display: flex!important;
			justify-content: flex-end;
			flex-direction: column;

			@media(max-width:991px) {
				height: 120px;
			}

			span {
				display: block;
				margin-top: 20px;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 35px;
			}
		}


		.grid img {
			width: auto;
			margin: 0 auto;
			display: inline-block;
		}

		.owl-nav {
			display: none;
		}
	}
}


/* 14.2 wpo-category-section-s2 */

.wpo-category-section-s2 {
	padding-left: 60px;
	padding-right: 60px;

	@media(max-width:1700px) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@media(max-width:1399px) {
		padding-left: 0px;
		padding-right: 0px;
	}

	.wpo-category-item {
		position: relative;

		@media(max-width:767px) {
			margin-bottom: 30px;
		}

		.wpo-category-text {
			position: absolute;
			left: 0;
			bottom: 0;
			text-align: center;
			width: 100%;
			padding: 30px;
			padding-bottom: 50px;

			@media(max-width:991px) {
				padding: 20px;
			}

			h3 {
				font-size: 50px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $white;

				@media(max-width:1199px) {
					font-size: 30px;
				}
				@media(max-width:767px) {
					font-size: 28px;
				}
				@media(max-width:575px) {
					font-size: 25px;
					margin-bottom: 10px;
				}

				a {
					color: $white; 
				}
			}

			p {
				color: $white; 	
				margin-bottom: 0;

				@media(max-width:991px) {
					font-size: 16px;
				}
			}
		}
	}
}

