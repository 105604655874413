/*======================================
8. Home-style-5 
 =======================================*/

/* 8.1 wpo-wedding-date-s3 */

.wpo-wedding-date-s3 {
	padding: 0;

	.wedding-date-wrap {
		margin-top: -130px;

		@media(max-width:1199px) {
			margin-top: -92px;
		}

		@media(max-width:991px) {
			margin-top: -62px;
		}

		@media(max-width:767px) {
			margin-top: 0;
			padding-top: 60px;
		}

		.react-countdown {
			.time-section {
				background-color: $theme-primary-color-s3;
				width: 260px;
				height: 260px;

				@media(max-width:1199px) {
					width: 185px;
					height: 185px;
				}

				@media(max-width:991px) {
					width: 125px;
					height: 125px;
				}

				&:before {
					display: none;
				}

				&:after {
					left: 50%;
					top: 50%;
					width: 85%;
					height: 85%;
					transform: translate(-50%, -50%);
					border-color: $white;
				}

				.time {
					margin-top: -30px;
					color: $white;

					@media(max-width:1199px) {
						margin-top: -10px;
					}
				}

				.time-text {
					color: $white;
				}
			}
		}
	}
}

/* 8.2 couple-section */

.couple-section {
	position: relative;
	overflow: hidden;
	z-index: 1;

	@include media-query(991px) {
		padding-top: 40px;
	}

	@include media-query(767px) {
		padding-top: 20px;
	}

	.container-fluid {
		@media(min-width:991px) {
			padding: 0;

		}
	}

	.couple-area {
		display: flex;
		align-items: center;
		justify-content: center;


		@include media-query(991px) {
			display: block;
		}

	}

	.middle-couple-pic {
		padding: 50px;
		border: 1px solid $theme-primary-color-s3;
		border-radius: 350px;
		width: 50%;
		max-width: 700px;
		z-index: 11;
		background: $white;

		@include media-query(1199px) {
			padding: 30px;
		}

		@media(max-width:991px) {
			width: 100%;
		}

		.middle-couple-pic-inner {
			overflow: hidden;
			border-radius: 350px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 350px;
				-webkit-animation: kenburn 25s 2s alternate infinite;
				-animation: kenburn 25s 2s alternate infinite;
			}
		}

		@include media-query(991px) {
			margin: 50px auto;
		}
	}

	.text-grid {
		width: 40%;
		padding: 30px 0;
		border-top: 1px solid $theme-primary-color-s3;
		border-bottom: 1px solid $theme-primary-color-s3;
		margin-right: -20px;

		@include media-query(991px) {
			max-width: 450px;
			margin: 0 auto;
		}


		.couple-text {
			padding: 80px 80px;
			background: $theme-primary-color-s3;

			@include media-query(1600px) {
				padding: 50px;
			}

			@include media-query(1399px) {
				padding: 30px;
			}

			@include media-query(1199px) {
				padding: 20px 30px;
			}
		}

		@include media-query(1199px) {
			padding: 15px 0;
		}


		@include media-query(991px) {
			width: 100%;
			padding: 0;
			text-align: center;
		}

		h3 {
			font-family: $base-font;
			font-size: 35px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 20px 0 0.4em;
			color: $white;

			@media(max-width:1199px) {
				font-size: 36px;
			}

			@media(max-width:991px) {
				font-size: 25px;
				margin-bottom: 10px;
			}

			@media(max-width:350px) {
				margin: 10px 0 0.2em;
			}
		}

		p {
			max-width: 342px;
			margin-bottom: 0;
			margin-left: auto;
			color: #E8E8E8;

			@include media-query(1199px) {
				font-size: 16px;
			}


			@media(max-width:991px) {
				font-size: 16px;
				max-width: 355px;
				margin: 0 auto;
				margin-bottom: 20px;
			}

		}

		ul {
			list-style: none;
			overflow: hidden;
			padding-top: 15px;
			display: flex;

			@include media-query(991px) {
				padding-top: 10px;
				display: flex;
				justify-content: center;
			}

			>li+li {
				margin-left: 25px;
			}

			li a {
				display: block;
				text-align: center;
				color: $white;
				font-size: 14px;

				&:hover {
					color: $theme-primary-color;
				}
			}
		}
	}

	.bride {
		padding-left: 0;
		text-align: right;

		@include media-query(991px) {
			text-align: center;
			margin: 0 auto;
		}

		.social {
			ul {
				justify-content: flex-end;
			}

			@include media-query(991px) {
				float: none;
				display: flex;
				justify-content: center;
			}
		}

		.couple-img {
			margin: 0;
			margin-bottom: 20px;
			margin-left: auto;

			@include media-query(991px) {
				text-align: center;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}
	}

	.groom {
		padding-right: 0;
		margin-right: 0;
		margin-left: -20px;

		@media(max-width:991px) {
			margin: 0 auto;
		}

		p {
			margin: 0;

			@media(max-width:991px) {
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}
	}
}

/* 8.3 wpo-story-section-s4 */
.wpo-story-section-s4 {
	.wpo-story-item {
		&+.wpo-story-item {
			padding-top: 60px;

			@media(max-width:991px) {
				padding-top: 30px;
			}
		}

		.wpo-story-img {
			position: relative;
			margin-right: 30px;
			overflow: hidden;

			@media(max-width:991px) {
				margin: 0;
			}

			img {
				transform: scale(1);
				transition: all .6s;
				width: 100%;
			}

			&:before {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 90%;
				height: 90%;
				content: "";
				border: 1px solid $white;
				z-index: 1;
			}
		}

		&:hover {
			.wpo-story-img {
				img {
					transform: scale(1.2);
				}

			}
		}

		.wpo-story-content {
			padding: 60px 30px;
			z-index: 11;
			position: relative;
			max-width: 546px;

			@media(max-width:991px) {
				text-align: center;
				margin: 0 auto;
			}

			@media(max-width:575px) {
				padding: 20px 0px;
			}

			h2 {
				font-size: 32px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 30px;
				margin-top: 0px;

				@media(max-width:1199px) {
					margin-bottom: 20px;
					font-size: 25px;
					line-height: 36px;
				}

				@media(max-width:575px) {
					font-size: 25px;
				}
			}

			span {
				font-style: normal;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				color: $theme-primary-color-s2;
				margin-bottom: 20px;
				display: block;

				@media(max-width:1199px) {
					margin-bottom: 10px;
				}

				@media(max-width:575px) {
					font-size: 18px;
				}
			}

			p {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 30px;
				color: $text-color;
				margin-bottom: 0px;

				@media(max-width:1399px) {
					font-size: 18px;
				}

				@media(max-width:1199px) {
					font-size: 16px;
				}

				@media(max-width:575px) {
					font-size: 17px;
				}
			}

		}

		&:nth-child(even) {
			.wpo-story-img {
				margin-right: 0px;
				margin-left: 30px;

				@media(max-width:991px) {
					margin: 0;
				}
			}

			.wpo-story-content {
				text-align: right;
				margin-left: auto;

				@media(max-width:991px) {
					text-align: center;
				}
			}
		}
	}
}

/* 8.4 wpo-video-section*/

.wpo-video-section,
.wpo-video-section-s2,
.wpo-video-section-s3 {
	position: relative;
	z-index: 1;

	.container-fluid {
		padding: 0;
	}


	.video-wrap {
		position: relative;
		z-index: 1;

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			background: rgba(1, 0, 60, 0.25);
			z-index: 1;
		}

		&::after {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 98%;
			height: 90%;
			content: "";
			border: 1px solid $white;
			z-index: 1;
			transform: translate(-50%, -50%);
		}

		img {
			width: 100%;
			object-fit: cover;
			@include media-query(575px) {
				min-height: 200px;
				object-fit: cover;
			}
		}
	}

	.container {
		@media(min-width:1600px) {
			max-width: 1400px;
		}
	}

	.video-btn {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 11;
	}
	.video-btn .wrap{
		width: 150px;
		height: 150px;
		border: 1px solid $white;
		text-align: center;
		border-radius: 50%;
		line-height: 150px;
		background: rgba(255, 255, 255, .2);

		@include media-query(991px) {
			width: 100px;
			height: 100px;
			line-height: 100px;
		}

		@include media-query(767px) {
			width: 80px;
			height: 80px;
			line-height: 80px;
		}

		.fi {
			position: relative;
			right: -3px;

			@include media-query(575px) {
				right: 0;
			}

			&:before {
				font-size: 40px;
				color: $white;

				@include media-query(991px) {
					font-size: 30px;
				}
			}
		}

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
			z-index: -1;
			border-radius: 50%;
			transition: all ease 5s;
			background: rgba(255, 255, 255, .2);
			animation-duration: 3s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: ripple;
		  }
		  &:after {
			animation-delay: 2s;
		  }
		  @keyframes ripple {
			0% {
			  transform: scale(1);
			  opacity: 0;
			}
			30% {
			  opacity: 1;
			}
			100% {
			  transform: scale(2.1);
			  opacity: 0;
			}
		}
	}
}
.wpo-video-section-s2 {
	padding-top: 60px;
	.video-wrap {
		position: relative;
		z-index: 1;
		background: #fff;
		-webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
		padding: 30px;
		overflow: hidden;

		@media(max-width:575px){
			padding: 15px;
		}

		&::before,
		&::after {
			display: none;
		}
	}
}
/* 8.5 wpo-portfolio-section-s4*/

.wpo-portfolio-section-s4,
.wpo-portfolio-section-s5 {
	.portfolio-grids {
		margin: 0 -7.5px;

		.grid {
			position: relative;
			margin: 0 auto;

			img {
				width: 100%;
			}

			.img-holder {
				border-radius: 0;

				.inner-wrap {
					display: block;
					position: relative;
					cursor: pointer;

					.hover-content {
						transition: all .3s;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						opacity: 0;
						text-align: center;
						width: 100%;

						span {
							color: $text-color;
						}


						i {
							font-size: 30px;
							color: $dark-gray;
						}
					}

					&:before {
						position: absolute;
						left: 2%;
						top: 2%;
						width: 96%;
						height: 96%;
						content: "";
						background: rgba(255, 255, 255, 0.8);
						opacity: 0;
						transition: all .3s;
						transform: scale(0);
					}

					&:after {
						position: absolute;
						left: 50%;
						top: 50%;
						width: 90%;
						height: 90%;
						content: "";
						border: 1px solid $white;
						transition: all .3s;
						transform: translate(-50%, -50%);
					}
				}
			}

			.img-holder:hover .inner-wrap .hover-content {
				opacity: 1;
			}

			.img-holder:hover {
				.inner-wrap:before {
					opacity: 1;
					transform: scale(1);
				}

			}
		}
	}
}

/* 8.6 wpo-contact-section-s5 */

.wpo-contact-section-s5 {
	position: relative;
	border-top: 1px solid $theme-primary-color-s3;

	.top-bg {
		position: absolute;
		left: 0;
		top: 20px;
		width: 100%;
		height: 661px;

		@media(max-width:991px) {
			height: 450px;
		}

		&:before {
			position: absolute;
			left: 0;
			bottom: -20px;
			width: 100%;
			height: 1px;
			background: $theme-primary-color-s3;
			content: "";
		}
	}


	.wpo-contact-section-wrapper {
		margin: 0;
		border-radius: 0;
		background: $theme-primary-color-s3;
		border: 0;
		max-width: 600px;
		padding: 30px;

		@media(max-width:575px) {
			padding: 15px;
		}

		.wpo-contact-form-area {
			border: 1px solid $white;
			padding: 40px 35px 40px;

			@media(max-width:575px) {
				padding: 40px 25px 40px;
			}
		}
	}
}

/* 8.9 wpo-blog-section-s3 */

.wpo-blog-section-s3 {
	background: $white;

	.wpo-blog-item {
		border-radius: 0;

		.wpo-blog-img {
			border-radius: 0;

			img {
				border-radius: 0;
			}
		}

	}

	&.soft-bg {
		background: #F8FAFF;
	}
	&.soft-bg2 {
		background: #F8F8FF;
	}
	&.soft-bg3 {
		background: #FFFBFA;
	}
	&.soft-bg4 {
		background: #F8F3F2;
	}

}