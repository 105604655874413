/*======================================
 13. wpo-invitation-area
 =======================================*/
.back-btn {
	display: block;
	width: 70px;
	height: 70px;
	line-height: 70px;
	background: #9db9cb;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 11;
	border-bottom-left-radius: 50px;

	&:hover {
		background: #7395ab;
	}

	i {
		font-size: 30px;
		color: $white;
		line-height: unset;
		position: relative;
		right: -8px;
		top: -3px;
	}
}

.invitation-header.style-2 {
	.back-btn {
		right: auto;
		left: 0;
		top: 0;
		border-radius: 0;
		border-bottom-right-radius: 50px;
		background-color: #1e3d3e;

		&:hover {
			background: #163334;
		}

		i {
			position: relative;
			left: -8px;
			top: -3px;
		}
	}
}

/*11.1 wpo-invitation-area */

.wpo-invitation-area {
	padding-top: 50px;
	position: relative;
	overflow: hidden;

	@media(max-width:575px) {
		padding-top: 10px;
	}

	.left-shape .shape,
	.right-shape .shape {
		position: absolute;
		top: -15%;
		z-index: 1;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: default;
		-webkit-animation-name: snowflakes-fall, snowflakes-shake;
		-webkit-animation-duration: 10s, 3s;
		-webkit-animation-timing-function: linear, ease-in-out;
		-webkit-animation-iteration-count: infinite, infinite;
		-webkit-animation-play-state: running, running;
		animation-name: snowflakes-fall, snowflakes-shake;
		animation-duration: 10s, 3s;
		animation-timing-function: linear, ease-in-out;
		animation-iteration-count: infinite, infinite;
		animation-play-state: running, running;

		img {
			@media (max-width:1400px) {
				max-width: 70%;
			}

			@media (max-width:991px) {
				max-width: 70%;
			}

			@media (max-width:575px) {
				max-width: 70%;
			}
		}

		&.s1 {
			left: 40px;
			-webkit-animation-delay: 0s, 0s;
			animation-delay: 0s, 0s
		}

		&.s2 {
			left: 140px;
			-webkit-animation-delay: 6s, .5s;
			animation-delay: 6s, .5s
		}

		&.s3 {
			left: 0;
			-webkit-animation-delay: 4s, 2s;
			animation-delay: 4s, 2s
		}

		&.s4 {
			left: 250px;
			-webkit-animation-delay: 2s, 2s;
			animation-delay: 2s, 2s
		}

		&.s5 {
			left: 40px;
			-webkit-animation-delay: 8s, 3s;
			animation-delay: 8s, 3s
		}
	}

	.left-shape {
		position: absolute;
		left: 0;
		top: 0;
		width: 30%;
		height: 100%;
	}

	.right-shape {
		position: absolute;
		right: 0;
		top: 0;
		width: 30%;
		height: 100%;

		.shape {
			&.s1 {
				left: auto;
				right: 40px;
				-webkit-animation-delay: 0s, 0s;
				animation-delay: 0s, 0s
			}

			&.s2 {
				left: auto;
				right: 140px;
				-webkit-animation-delay: 6s, .5s;
				animation-delay: 6s, .5s
			}

			&.s3 {
				left: auto;
				right: 0;
				-webkit-animation-delay: 4s, 2s;
				animation-delay: 4s, 2s
			}

			&.s4 {
				left: auto;
				right: 250px;
				-webkit-animation-delay: 2s, 2s;
				animation-delay: 2s, 2s
			}

			&.s5 {
				left: auto;
				right: 40px;
				-webkit-animation-delay: 8s, 3s;
				animation-delay: 8s, 3s
			}

		}

	}

	.wpo-invitation-wrap {
		background: $white;
		max-width: 1400px;
		margin: 0 auto;

		@media(max-width:1440px) {
			max-width: 1000px;
		}

		@media(max-width:1199px) {
			max-width: 900px;
		}

		@media(max-width:991px) {
			padding: 30px;
			max-width: 800px;
		}

		@media(max-width:575px) {
			padding: 10px;
		}


		.middle-couple-pic {
			max-width: 640px;
			margin: 0 auto;
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 20px;
			box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.06);
			background: $white;
			border-radius: 320px;
			margin-bottom: 80px;


			@include media-query(370px) {
				padding: 10px;
			}

			.shape {
				position: absolute;
				left: -80px;
				top: -30px;

				@media(max-width:600px) {
					left: 0;
					top: -50px;
				}

				@media(max-width:450px) {
					left: -25px;
					top: -55px;
				}

				img {
					@media(max-width:600px) {
						max-width: 200px;
					}
				}
			}

			.shape2 {
				position: absolute;
				right: -220px;
				bottom: -175px;

				@media(max-width:600px) {
					right: -100px;
					bottom: -130px;
				}

				img {
					@media(max-width:600px) {
						max-width: 500px;
					}

					@media(max-width:450px) {
						max-width: 365px;
					}
				}
			}

			.middle-couple-pic-inner {
				border-radius: 320px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					border-radius: 320px;
					-webkit-animation: kenburn 20s 1.2s alternate infinite;
					-animation: kenburn 20s 1.2s alternate infinite;
				}
			}

			@include media-query(991px) {
				margin-bottom: 90px;
			}

			@include media-query(370px) {
				margin-bottom: 60px;
			}

		}

		.hero-text {
			text-align: center;
			padding-right: 0px;
			margin-bottom: 80px;

			@media(max-width:991px) {
				padding-right: 0;
			}

			h2 {
				color: $theme-primary-color-s3;
				text-align: center;
				font-size: 70px;
				line-height: 110px;
				font-style: normal;
				font-weight: 400;
				text-align: center;
				margin: 0 auto;
				margin-bottom: 30px;


				@include media-query(1399px) {
					font-size: 52px;
					margin-bottom: 30px;
					line-height: 85px;
				}

				@include media-query(1100px) {
					font-size: 50px;
					margin-bottom: 20px;
					line-height: 75px;
				}

				@media(max-width:600px) {
					font-size: 40px;
					line-height: 55px;
				}
			}

			span {
				font-size: 25px;
				font-style: normal;
				font-weight: 600;
				line-height: 35px;

				@media(max-width:1199px) {
					font-size: 20px;
				}

				@media(max-width:575px) {
					font-size: 18px;
				}
			}

			p {
				font-size: 50px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				color: #87748F;
				margin-bottom: 0;
				margin-top: 30px;

				@media(max-width:1399px) {
					font-size: 45px;
				}

				@media(max-width:1199px) {
					font-size: 40px;
				}

				@media(max-width:575px) {
					font-size: 30px;
					margin-top: 20px;
				}
			}

		}

		.wedding-date-wrap {
			max-width: 950.998px;
			margin: 0 auto;
			padding-top: 70px;

			@media(max-width:575px) {
				padding-top: 35px;
			}

			.react-countdown {
				.time-section {
					width: 180px;
					height: 180px;
					background: #EDEBFF;


					@media(max-width:1199px) {
						width: 110px;
						height: 110px;
						margin: 0 10px;
					}

					@media(max-width:600px) {
						margin-bottom: 20px;
					}

					&:after {
						border-color: #524FAA;
					}

					&:before {
						background: url(../../images/wedding-date/7.svg) no-repeat center center;
						left: auto;
						right: -50px;
						bottom: -65px;

						@include media-query(1199px) {
							display: none;
						}
					}


					&:nth-child(even) {
						&:before {
							background: url(../../images/wedding-date/8.svg) no-repeat center center;
							left: auto;
							right: -65px;
						}
					}

					.time {
						font-size: 60px;
						line-height: 80px;
						color: #657150;

						@media(max-width:1199px) {
							font-size: 40px;
							line-height: 60px;
						}
					}
				}
			}
		}

		.wpo-event-text {
			h2 {
				padding: 10px 0;
				background: $theme-primary-color-s3;
				font-size: 35px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $white;

				@media(max-width:757px) {
					font-size: 25px;
				}
			}

			ul {
				list-style: none;
				margin-top: 80px;
				text-align: center;

				@media(max-width:991px) {
					margin-top: 40px;
				}


				li {
					font-size: 30px;
					font-style: normal;
					font-weight: 500;
					line-height: 60px;
					margin-bottom: 10px;

					@include media-query(991px) {
						font-size: 18px;
						line-height: 40px;
					}

					@include media-query(370px) {
						font-size: 16px;
						line-height: 30px;
						margin-bottom: 20px;
					}


					&:last-child {
						margin-bottom: 0;
					}

					button {
						color: #473F92;
						text-align: center;
						font-size: 30px;
						font-style: normal;
						font-weight: 600;
						line-height: 60px;
						text-decoration-line: underline;
						display: inline-block;
						position: relative;
						text-transform: capitalize;

						@include media-query(991px) {
							font-size: 18px;
						}

						@include media-query(370px) {
							font-size: 16px;
						}

						&:hover,
						&.active,
						&:focus{
                           background: none;
						   box-shadow: none;
						}
					}
				}
			}
		}

	}
}



/*======================================
 12. wpo-invitation-area-s2
 =======================================*/

.wpo-invitation-area-s2,
.wpo-coming-soon-area {
	height: 100vh;
	min-height: 920px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@include media-query(991px) {
		padding: 30px 0;
		min-height: 600px;
		padding-top: 145px;
	}

	.container {
		@include media-query(767px) {
			max-width: 100%;
		}
	}

	.hero-inner {
		.row {
			justify-content: center;
		}
	}

	.wpo-event-wrap {
		max-width: 600px;
		margin-left: auto;
		position: relative;
		background: url(../../images/slider/invite-bg.png);
		overflow: hidden;
		padding: 15px;
		z-index: 1;

		@include media-query(991px) {
			margin: 0 auto;
		}

		@include media-query(767px) {
			padding: 20px;
		}

		@include media-query(450px) {
			padding: 10px;
		}

		.frame-shape-1 {
			position: absolute;
			left: -50px;
			bottom: -100px;
			z-index: -1;

			@include media-query(991px) {
				left: -50px;
				bottom: -100px;
			}

			@media(max-width:575px) {
				max-width: 240px;
				bottom: -30px;
				left: -30px;
			}
		}

		.frame-shape-2 {
			position: absolute;
			right: -50px;
			top: -100px;
			z-index: -1;

			@include media-query(991px) {
				right: -50px;
				top: -100px;
			}

			@media(max-width:575px) {
				max-width: 240px;
				top: -30px;
				right: -30px;
			}
		}
	}


	.wpo-event-item {
		padding: 120px 30px 120px;
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		background-size: contain;
		border: 1px solid #a9c49b;

		@media(max-width:575px) {
			width: 100%;
			padding: 80px 30px 80px;
		}

		.wpo-event-text {
			text-align: center;

			h2 {
				font-size: 45px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 30px;

				@media(max-width:991px) {
					font-size: 45px;
				}

				@media(max-width:767px) {
					font-size: 40px;
				}

				@media(max-width:575px) {
					font-size: 30px;
				}
			}

			p {
				font-weight: 400;
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 30px;
				color: $text-color;

				@media(max-width:991px) {
					font-size: 22px;
					line-height: 33px;
				}

				@include media-query(767px) {
					margin-bottom: 10px;
				}

				@media(max-width:767px) {
					font-size: 18px;
					margin-bottom: 5px;
				}
			}

			ul {
				list-style: none;
				max-width: 416px;
				margin: auto;
				margin-top: 70px;

				@media(max-width:767px) {
					margin-top: 40px;
				}


				li {
					margin-bottom: 25px;
					font-size: 20px;
					line-height: 30px;
					margin-left: 0;
					float: none;
					color: $text-color;

					@media(max-width:767px) {
						font-size: 16px;
						margin-bottom: 5px;
					}

					@media(max-width:450px) {
						margin-bottom: 2px;
					}

					&:last-child {
						margin-bottom: 0;
					}

					button {
						font-size: 16px;
						font-weight: 500;
						color: $theme-primary-color;
						position: relative;
						display: inline-block;
						text-transform: capitalize;

						@media(max-width:767px) {
							margin-top: 10px;
						}

						&:before {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
							content: "";
							background: $theme-primary-color;
						}

						&:hover,
						&.active,
						&:focus{
                           background: none;
						   box-shadow: none;
						}
					}
				}
			}
		}
	}
}

@-webkit-keyframes snowflakes-fall {
	0% {
		top: -10%
	}

	100% {
		top: 100%
	}
}

@-webkit-keyframes snowflakes-shake {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px)
	}

	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px)
	}

	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px)
	}
}

@keyframes snowflakes-fall {
	0% {
		top: -10%
	}

	100% {
		top: 100%
	}
}

@keyframes snowflakes-shake {
	0% {
		transform: translateX(0px)
	}

	50% {
		transform: translateX(80px)
	}

	100% {
		transform: translateX(0px)
	}
}